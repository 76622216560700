import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import { FaFacebook, FaWhatsapp } from "react-icons/fa";
import { SiGooglemybusiness } from "react-icons/si";
import ContactForm from "../components/contactForm";
import Hero from "../components/hero";
import Layout from "../components/layout";
import { Button } from "react-bootstrap";
import parse from "html-react-parser";

const ContactUsPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText

				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			wpSeoPage(title: { eq: "Contact Us" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}
			wpContactPage {
				contactPageFields {
					bookAMeetingSection {
						title
						description
					}
					heroSection {
						title
						description
					}
					meetingsOnlineSection {
						title
						description
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const {
		wpContactPage: {
			contactPageFields: {
				bookAMeetingSection,
				heroSection,
				meetingsOnlineSection,
			},
		},
	} = data;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Contact Us",
				item: {
					url: `${siteUrl}/contact-us`,
					id: `${siteUrl}/contact-us`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/contact-us`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<Hero
						customTitle
						button1={null}
						button2={null}
						button3={null}
						backgroundImage={data.heroImg.gatsbyImage}
						title={heroSection.title}
						description={heroSection.description}
					/>
				</section>

				{/* <section>
					<iframe
						className="position-relative google-map"
						src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9928.942998834771!2d-0.0886605!3d51.5272357!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761d0137a088e3%3A0xd6972f4fcb15756d!2sRJM%20Digital!5e0!3m2!1sen!2suk!4v1668688676659!5m2!1sen!2suk"
						width="100%"
						style="border:0;"
						allowfullscreen=""
						loading="lazy"
						referrerpolicy="no-referrer-when-downgrade"
					></iframe>
				</section> */}
				<section className="bg-light-grey position-relative ">
					<Container>
						<Row className=" justify-content-between ">
							<Col lg={7} xl={5} className=" ">
								<div className="p-md-5 py-5 position-relative contact-page-section bg-light-grey">
									<h2 className="display-5 text-primary mb-3">Visit Us</h2>
									<Row>
										<Col md={6}>
											<h3 className="text-primary mt-4">London</h3>

											<a
												className="black-link ssp-regular d-block fs-5"
												target="_blank"
												rel="noreferrer"
												href="https://goo.gl/maps/3YdonbxfjTTfNWVt8"
											>
												124 City Road,
												<br /> London,
												<br /> EC1V 2NX
											</a>
											<Button
												target="_blank"
												rel="noreferrer"
												href="https://rjm-digital.rjmdigital.net/wp-content/uploads/2023/07/How-to-get-to-RJM-London.pdf"
												style={{ borderRadius: "100px", fontSize: "75%" }}
												className=" px-3 py-1 mt-2"
												variant="secondary"
											>
												How to find us
											</Button>
											<h3 className="text-primary mt-4">Phone</h3>

											<a
												className="black-link ssp-regular fs-5"
												href="tel:+442033550982"
											>
												020 3355 0982
											</a>
											<h3 className="text-primary mt-4">Email </h3>

											<a
												className="black-link ssp-regular fs-5"
												href="mailto:hello@rjm.digital"
											>
												hello@rjm.digital
											</a>
											<div className="d-block pt-4 ">
												<ul className="ps-0 mb-1">
													<li className="d-inline-block me-3 ">
														<a
															target="_blank"
															rel="noreferrer"
															className=" black-link fs-3 social-icon whatsapp-link-cta"
															href="https://wa.me/message/TULP6QAAUXB3M1"
														>
															<FaWhatsapp className="whatsapp-link-cta" />
														</a>
													</li>
													<li className="d-inline-block me-3 ">
														<a
															target="_blank"
															rel="noreferrer"
															className=" black-link fs-3 social-icon"
															href="https://m.me/rjmdigital"
														>
															<FaFacebook />
														</a>
													</li>
													<li className="d-inline-block  ">
														<a
															target="_blank"
															rel="noreferrer"
															className=" black-link fs-3 social-icon"
															href="https://goo.gl/maps/k94GGPCinyvUWxGh9"
														>
															<SiGooglemybusiness />
														</a>
													</li>
												</ul>
											</div>
										</Col>
										<Col md={6}>
											<h3 className="text-primary mt-4">Dubai</h3>

											<a
												className="black-link ssp-regular fs-5 d-block"
												target="_blank"
												rel="noreferrer"
												href="https://maps.app.goo.gl/P2U865qZ8HrSoGfP6"
											>
												Astrolabs - Cluster R,
												<br /> JLT,
												<br /> Dubai
											</a>
											<Button
												target="_blank"
												rel="noreferrer"
												href="https://rjm-digital.rjmdigital.net/wp-content/uploads/2023/07/How-to-get-to-RJM-Dubai.pdf"
												style={{ borderRadius: "100px", fontSize: "75%" }}
												className=" px-3 py-1 mt-2"
												variant="secondary"
											>
												How to find us
											</Button>
											<h3 className="text-primary mt-4">Phone</h3>

											<a
												className="black-link ssp-regular fs-5"
												href="tel:+971525785992"
											>
												0525 785 992{" "}
											</a>
											<h3 className="text-primary mt-4">Email </h3>

											<a
												className="black-link ssp-regular fs-5"
												href="mailto:hello@rjm.digital"
											>
												hello@rjm.digital
											</a>
											<div className="d-block pt-4 ">
												<ul className="ps-0 mb-1">
													<li className="d-inline-block  ">
														<a
															target="_blank"
															rel="noreferrer"
															className=" black-link fs-3 social-icon"
															href="https://maps.app.goo.gl/P2U865qZ8HrSoGfP6"
														>
															<SiGooglemybusiness />
														</a>
													</li>
												</ul>
											</div>
										</Col>
									</Row>
								</div>
								{/* <div className="d-flex align-items-center">
									<StaticImage
										src="../images/Contact-logo-3.svg"
										placeholder="blurred"
										quality={100}
										style={{ maxWidth: "80px" }}
										className="me-3"
									/>
									<StaticImage
										src="../images/Contact-logo-2.svg"
										placeholder="blurred"
										quality={100}
										style={{ maxWidth: "80px" }}
										className="me-3"
									/>
									<StaticImage
										src="../images/Contact-logo-1.svg"
										placeholder="blurred"
										quality={100}
										style={{ maxWidth: "80px" }}
									/>
								</div>
								<div>{parse(meetingsOnlineSection.title)}</div>
								<p className="pb-5 pb-lg-7">
									{meetingsOnlineSection.description}
								</p> */}
							</Col>
							<Col className=" mt-lg-4" lg={5} xl={6}>
								<h2 className="display-5  text-primary mb-5">
									Request a call back
								</h2>
								<ContactForm />
							</Col>
						</Row>
					</Container>
				</section>
				<section className="pt-5 pt-lg-7 pb-xl-7 bg-light-grey">
					<Container>
						<Row>
							<Col className="text-center">
								{" "}
								<div>{parse(bookAMeetingSection.title)}</div>
								<p>{bookAMeetingSection.description}</p>
							</Col>
						</Row>
						<Row>
							<Col className="d-xl-none">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "1150px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
								/>
							</Col>
							<Col className="d-none d-xl-block">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "700px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
								/>
							</Col>
						</Row>
					</Container>
				</section>
			</Layout>
		</>
	);
};

export default ContactUsPage;
